<template>
  <KSelect :items="items" v-bind="$attrs" v-on="$listeners" ref="autocomplete" item-value="key" item-text="value"/>
</template>

<script>
import { eventTypeSelect } from '@/api/endpoints/eventType.js';
import KSelect from "@/components/crud/fields/KSelect.vue";

export default {
  name: 'EventTypeSelect',
  components: { KSelect },
  data: () => ({
    items: [],
  }),
  async created() {
    const response = await eventTypeSelect();
    this.items = response.data; //@OTOD backend for some reason doens't return data.data here.. weird..
  },
};
</script>
