<template>
  <KCrudLayout :filters.sync="filters">
  <template #filters="{attrs, on}">
    <WebhookLogFilter v-bind="attrs" v-on="on"/>
  </template>
  <template #view.list>
    <resource
      class="webhook-resource"
      :can-add="false"
      :can-delete="false"
      :can-update="false"
      :show-speed-dial="true"
      :parameters="filters"
      :index-request="indexHandler"
      :meta="{name: $tc('webhook.title', 1), namePlural: $tc('webhook.title', 2)}"
      :table-content="tableContent"
      @row-click="openWebhookLog"
      ref="resource">
    </resource>

    <v-dialog v-model="isWebhookLogDialogOpen" width="800">
      <v-card>
        <v-card-title>
          {{ $t('webhook.logTitle') }}
        </v-card-title>
        <v-card-text>
          <pre>{{dialogBody}}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
</KCrudLayout>
</template>

<script lang="js">
import { index} from '@/api/endpoints/webhookLog.js';
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { mapGetters } from "vuex";
import dayJs from "@/plugins/dayJs.js";
import WebhookLogFilter from "@/components/forms/WebhookLogFilter.vue";
import KCrudLayout from "@/components/layout/KCrudLayout.vue";

export default {
  name: 'WebhookResource',
  components: {
    KCrudLayout,
    WebhookLogFilter,
    Resource,
  },
  props: {
    client: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    tableContent() {
      return [
        {
          text: this.$t('webhook.logFields.eventType'),
          align: 'left',
          sortable: true,
          value: 'eventType',
        },
        {
          text: this.$t('webhook.logFields.referenceType'),
          align: 'left',
          sortable: true,
          value: 'referenceType',
        },
        {
          text: this.$t('webhook.logFields.reference'),
          align: 'left',
          sortable: true,
          value: 'reference',
        },
        {
          text: this.$t('webhook.logFields.url'),
          align: 'left',
          sortable: true,
          value: 'url',
        },
        {
          text: this.$t('webhook.logFields.responseCode'),
          align: 'left',
          sortable: true,
          value: 'responseCode',
        },
        {
          text: this.$t('webhook.logFields.createdAt'),
          align: 'left',
          sortable: true,
          value: 'createdAt',
        },
      ];
    },
  },
  data() {
    return {
      isWebhookLogDialogOpen: false,
      dialogBody: "",
      filters: {},
    };
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'client.index' },
        text: this.$tc('client.title', 2),
      },
      {
        exact: true,
        to: { name: 'client.connectors' },
        text: this.client.name,
      },
      {
        exact: true,
        to: { name: 'client.webhook' },
        text: this.$tc('webhook.title', 2),
      },
      {
        exact: true,
        to: { name: 'client.webhook.logs' },
        text: this.$tc('webhook.logTitle', 2),
      },
    ]);
  },
  methods: {
    async indexHandler() {
      const response = await index(this.$route.params.clientId, ...arguments);

      response.data.data.map(data => {
        data.createdAt = dayJs(data.createdAt).format('LLL');
        return data;
      });

      return response;
    },
    openWebhookLog(logId) {
      const log = this.$refs.resource.$refs.resourceList.items.find(item => item.id === logId);
      this.isWebhookLogDialogOpen = true;
      this.dialogBody = JSON.stringify(JSON.parse(log.body), null, 2);
    }
  },
};
</script>
