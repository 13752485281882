<template>
  <k-field-group language-prefix="webhook.logFields">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('global.applyFilter')"
               :title="$t('global.filterTitle', { module: $t('webhook.logTitle') })"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <EventTypeSelect v-model="filters.eventType" field="eventType" clearable/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import EventTypeSelect from "@/components/autocompletes/EventType.vue";

export default {
  name: 'WebhookLogFilter',
  components: {
    EventTypeSelect,
    KSidebar,
    KFieldGroup,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
    instructionTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
  },
};
</script>
